.payment-success-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between; /* Adjust to space-between for better spacing */
  padding: 20px;
  text-align: center;
  min-height: 100vh;
  background-color: #ffffff;
  color: #333;
  font-family: 'Arial', sans-serif;
}

.payment-success-container .pic {
  max-width: 100%; /* Full width on mobile */
  height: auto;
  margin-bottom: 20px;
}

.serviced-by {
  align-self: stretch;
  text-align: center;
  margin-top: auto; /* Push to the bottom */
}

.serviced-by .logo {
  max-width: 150px;
  height: auto;
  margin-bottom: 20px;
}

/* Adjusting font sizes for better readability on mobile */
.payment-success-container h1, 
.payment-success-container p, 
.payment-success-container .serviced-by h3 {
  font-size: 1.2em;
  color: #4a4a4a;
  margin-bottom: 10px;
}

/* Responsive Design for larger screens */
@media (min-width: 600px) {
  .payment-success-container .pic {
    max-width: 50%; /* Restrict size on larger screens */
  }

  .payment-success-container h1, 
  .payment-success-container p, 
  .payment-success-container .serviced-by h3 {
    font-size: 1.2em;
  }
}

/* Animations and hover effects remain the same */