.menu-creator {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px;
    padding-top: 10px;
    background-color: #ffffff00;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.menu-creator h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

.category-section {
    background-color: #fff;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.425);
}

.currency-selector {
    display: flex;
    justify-content: center;
    gap: 150px;
    padding-top: 30px;
    padding-bottom: 10px;
}

.category-title {
    display: flex;
    align-items: center;
}

.item-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
}

.bottom-bar {
    position: fixed; /* Positioning the bar at the bottom */
    right: 20px; /* Adjust as needed */
    bottom: 20px; /* Adjust as needed */
    display: flex;
    justify-content: flex-end;
}

@media (max-width: 768px) {
    .menu-creator {
        width: 150%;
        padding: 10px;
        margin-left: 15%;
    }

    .category-name-input,
    .category-tax-input,
    .item-name-input,
    .item-description-input,
    .item-price-input,
    .item-tax-input {
        width: 100%;
    }   
}

.button-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Adjust as needed */
  }

