.cart-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Adjust alignment to flex-start */
    padding: 30px;
    max-width: 800px;
    margin: 0 auto;
    overflow: hidden;
}



.back-navigation {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;
    width: 100%; /* Ensure it takes full width */
    justify-content: flex-start; /* Align to the left */
}

.cart-item {
    display: grid;
    grid-template-columns: 80px 188px 92px; /* Default three columns */
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
    padding: 10px;
    border-bottom: 1px solid #eee;
}

/* Adjust column widths for screens between 200px and 350px */
@media (max-width: 370px) {
    .cart-item {
        grid-template-columns: 80px 140px 80px;
    }
}

/* Adjust column widths for screens narrower than 200px */
@media (max-width: 200px) {
    .cart-item {
        grid-template-columns: 60px 120px 40px;
    }
}
.item-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
}

.item-name, .item-description {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    justify-content: center;
}

.quantity-controls {
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Aligns to the left */
}

.price-remove {
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: flex-end; /* Aligns price and delete icon to the right */
}


/* Styles for icons, similar to your existing style if any */
.plus-icon, .minus-icon, .delete-icon {
    cursor: pointer;
}

/* Styles for the special request input */
.special-request {
    width: 100%;
    margin-top: 20px;
}

.special-request label span {
    font-weight: bold;
    margin-right: 5px;
}

.special-request-input {
    width: 100%;
    height: 40px;
    padding: 0 5px;
}

/* Styles for the total price display */
.total-price {
    margin-top: 20px;
    font-size: 1.2em;
}

/* Styles for the payment section */
.payment-section {
    width: 100%;
    margin-top: 30px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.payment-section h3 {
    text-align: center;
    margin-bottom: 20px;
}

.payment-options {
    display: flex;
    flex-direction: column;
}

.payment-options input[type="radio"] {
    margin-right: 10px;
}

.payment-options label {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.StripeElement {
    margin-bottom: 10px;
    padding: 10px 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
}

