@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap'); /* Example font */

.container {
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
}

.logo-container {
    margin-bottom: 20px;
}

.input-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.input-field {
    width: 100px; /* Narrower width for 4 digits */
    height: 50px; /* Taller input field */
    padding: 10px;
    margin: 0 auto 20px; /* Center the input field and add bottom margin */
    font-size: 1.5em; /* Larger font size for the input numbers */
    border: 1px solid #ddd;
    border-radius: 4px;
    text-align: center; /* Center the text inside the input field */
}

.submit-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: #0056b3;
}

.restaurant-name, .restaurant-logo {
    font-family: 'Lobster', cursive; /* Custom font */
    font-size: 2.5rem; /* Larger font size */
    color: #2a2a2a; /* A nice color */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Text shadow for depth */
    animation: fadeIn 2s; /* Animation */
    text-align: center; /* Centered text */
    margin-top: 10px; /* Spacing */
}

.restaurant-logo {
    max-width: 300px; /* Control the size of the restaurant logo */
    margin: 20px auto; /* Center and add margin */
}

.logo-container-powered {
    text-align: center; /* Center the content */
    margin-top: 50px; /* Spacing from the content above */
}

.powered-logo {
    max-width: 150px; /* Control the size of the powered logo */
    margin: 10px auto; /* Center and add margin */
    display: block; /* Ensure it's a block for margin to work */
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}
