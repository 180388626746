.billing-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Adjust height as needed */
    background-color: #f4f4f4; /* Background color */
  }
  
  .billing-content {
    text-align: center;
    max-width: 400px;
    padding: 20px;
    background: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .billing-description {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .stripe-setup-button {
    background-color: #635BFF; /* Stripe's brand color */
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .stripe-setup-button:hover {
    background-color: #5247cc; /* Darken on hover */
  }
  
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border-left-color: #09f;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  .stripe-setup-button {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: #0070ba;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .stripe-setup-button:disabled {
    background-color: #bfbfbf;
    cursor: not-allowed;
  }
  
  .stripe-setup-button .spinner {
    display: inline-block;
    vertical-align: middle;
  }
  