.personal-info {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    box-sizing: border-box;
    background-color: #f4f4f494;
    border-radius: 8px;
} 

.personal-info h1, .personal-info h2 {
    text-align: center;
    color: #333;
}

.address-row, .owner-row {
    display: flex;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.personal-info .MuiFormControl-root {
    flex-grow: 1;
    min-width: 180px;
}

/* Responsive design */
@media (max-width: 768px) {
    .address-row, .owner-row {
        flex-direction: column;
    }

    .personal-info {
        padding: 10px;
    }
}

.centered-input {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.section-header {
    display: flex;
    align-items: center;
    margin-left: -24px; /* Adjust as needed */
    margin-top: 20px; /* Adds space between sections */
}

.section-title {
    cursor: 'pointer';
    margin: '0 0 0 8px';
    padding-left: 20px; /* Adjust to move closer to the icon */
}

.section-content {
 /*   border: 1px solid #ccc; */
    padding: 10px;
    margin-top: 10px;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    z-index: 1000; /* Ensure it's above other elements */
}

.overlay .MuiAlert-root { /* Specific to Material-UI Alert component */
    z-index: 1001;
}

/* Centering logo and upload button */
.logo-container {
    display: flex; /* Enable Flexbox layout */
    flex-direction: column; /* Arrange items vertically */
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center items vertically */
    padding: 10px; /* Adds padding around the container */
}
